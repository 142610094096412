<template>
  <div class="containers">
    <div class="doms">
      <div class="inputs">
        {{query.company_name}}
      </div>
      <div class="inputs" style="padding-left: 15px">
        xxxxxxxxxxxx
      </div>
      <div class="inputs">
        <div class="itemTitle">申 请 人：</div>
        <van-field class="inputsItem" v-model="query.name" />
      </div>
      <div class="inputs">
        <div class="itemTitle">身 份 证 号 码：</div>
        <van-field class="inputsItem" v-model="query.ID_number" />
      </div>
      <div class="inputs">
        <div class="itemTitle">住 址：</div>
        <van-field class="inputsItem" v-model="query.address" />
      </div>
      <div class="inputs">
        <div class="itemTitle">电 话：</div>
        <van-field class="inputsItem" v-model="query.phone" />
      </div>
      <div class="inputs">
        <div class="itemTitle">微 信：</div>
        <van-field class="inputsItem" v-model="query.weixin" />
      </div>
      <div class="inputs selfStyle">
        <div class="itemTitle">日 期：</div>
        <van-field class="inputs1 inputBorder36" v-model="query.select_year" />
        年
        <van-field class="inputs1 inputBorder36" v-model="query.select_month" />
        月
        <van-field class="inputs1 inputBorder36" v-model="query.select_day" />
        日
      </div>
      <div class="submitBtn" @click="submitInfo">保存</div>
    </div>
  </div>
</template>

<script>
import {SaveInsurance, LoadInsurance } from "@/api/paper";

export default {
  data() {
    return {
      query: {},
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      LoadInsurance({openId: this.$store.state.info.openId}).then(res => {
        if(res.row && res.row.name) {
          this.query = res.row
        }
      })
    },
    submitInfo() {
      if(!this.query.name) {
        return  this.$notify({
          type: "warning",
          message: "请输入申请人",
        });
      }
      if(!this.query.ID_number) {
        return  this.$notify({
          type: "warning",
          message: "请输入身份证号码",
        });
      }
      if(!this.query.address) {
        return  this.$notify({
          type: "warning",
          message: "请输入住址",
        });
      }
      if(!this.query.phone) {
        return  this.$notify({
          type: "warning",
          message: "请输入电话",
        });
      }
      if(!this.query.weixin) {
        return  this.$notify({
          type: "warning",
          message: "请输入微信",
        });
      }
      if(!this.query.select_year || !this.query.select_month || !this.query.select_day) {
        return this.$notify({
          type: "warning",
          message: "请将日期填写完整",
        });
      }
      this.query.openId = this.$store.state.info.openId
      SaveInsurance(this.query).then(res => {
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.containers {
  box-sizing: border-box;
  padding: 28px;
  font-family: PingFang SC;
  font-size: 12px;
  color: #777777;
  line-height: 16px;
}
.inputs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .itemTitle {
    flex-shrink: 0;
    width: 87px;
    text-align: right;
  }
  .inputsItem {
    font-size: 12px;
    border: 1px solid #DDDDDD;
    padding: 0 5px !important;
  }
}
.submitBtn {
  background: #02DBCA;
  border-radius: 20px;
  color: #FFFFFF;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.inputs1 {
  font-size: 12px;
  border-bottom: 1px solid #DDDDDD;
  text-align: center !important;
  padding: 0 !important;
}
.inputBorder36 {
  width: 36px;
}
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
